import React, { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'

import Footer from '../../components/Footer'
import PageHeader from '../../components/PageHeader'
import Lettering from '../../components/Lettering'
import api from '../../services/api'

import './styles.css'

interface ILettering {
  title: string;
  description: string;
  link: string;
  imageName: string;
  imageUrl: string;
}

const Mural: React.FC = () => {

  const [letterings, setLetterings] = useState<ILettering[]>([]);

  useEffect(() => {
    api.get('/lettering').then(response => {
      setLetterings(response.data)
    })
  }, []);

  return (
    <div id="page-mural" className="container">
      <PageHeader />

      <div className="title">
        <h2>Mural Alira notes</h2>
        <p>Confira os clicks de nossas seguidoras utilizando os produtinhos da Alira. Quer ver mais artes e dicas de estudos? Sigam elas no Insta {':)'}</p>
      </div>
      <main>
        {letterings.map(
          (lettering, index) => (
            <Lettering
              key={lettering.imageUrl}
              title={lettering.title}
              description={lettering.description}
              link={lettering.link}
              image={lettering.imageUrl}
              alt={lettering.imageName}
              position={index%2 ? "right" : "left"}
            />
          )
        )}

      </main>

      <Footer />
    </div>
  )
}

export default Mural
