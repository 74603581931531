import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface BannerProps {
  banners: Array<{
    id: number;
    name: string;
    url: string;
  }>
}

const BannersCarousel: React.FC<BannerProps> = ({ banners }) => {

  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      swipeable={false}
      useKeyboardArrows={false}
      showIndicators
      stopOnHover
      infiniteLoop
      autoPlay
      interval={4000}
      transitionTime={1000}
    >
      {banners.map((image, index) => {
        return (
          <div key={image.url}>
            <img src={image.url} alt={image.name} />
          </div>
        )
      })}
    </Carousel>
  )
}

export default BannersCarousel
