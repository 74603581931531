import React from 'react';

import './styles.css'

interface LetteringProps {
  title: string;
  description: string;
  link: string;
  image: string;
  alt: string;
  position: string;
}

const Lettering: React.FC<LetteringProps> = ({title, description, link, image, alt, position}) => {
  return (
    <article className="lettering-card">

      <div className={`lettering-content ${position}`}>
        <img src={image} alt={alt}/>

        <div className="lettering-description">
          <h2><a href={link}>{title}</a></h2>
          <p>{description}</p>
        </div>

      </div>

    </article>
  );
}

export default Lettering;
