import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface ProductsCarouselProps {
  images: Array<{
    url: string
  }>
}

const ProductsCarousel: React.FC<ProductsCarouselProps> = ({ images }) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      infiniteLoop
      showThumbs
      thumbWidth={64}
      useKeyboardArrows
      stopOnHover
    >
      {images.map((image, index) => {
        return (
          <div key={image.url}>
            <img src={image.url} alt={`imagem_${index}`} />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ProductsCarousel
