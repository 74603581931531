import React from 'react'

import Routes from './routes'

import './assets/styles/global.css'
import AppProvider from './context'

const App: React.FC = () => {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  )
}

export default App
